<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning" v-if="!user.xentri_id || !user.account_id">
                        <router-link :to="{ name: 'user-check' }" class="white--text">{{ whatRequiresAttention }}</router-link>
                    </v-alert>
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-app-bar-title>OpenVPN Password</v-app-bar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon :to="{ name: 'user-dashboard' }">
                                <font-awesome-icon :icon="['fas', 'times']" style="font-size: 16px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text>
                            <!-- <p class="text-overline mb-0 mt-5">Password</p> -->
                            <p>
                                The password you will use to connect to the OpenVPN server is different than
                                your account password. This allows you to share VPN access with anyone without
                                giving them any access to your profile or account.
                            </p>
                            <v-btn color="indigo darken-2 white--text" @click="createOpenvpnPassword">Generate password</v-btn>
                            <p v-if="showTable" class="mt-4">
                                Copy the username and password to the OpenVPN software when you connect the first time.
                                It has an option that allows you to save the password.
                            </p>
                            <table v-if="showTable" class="mt-4 mb-4 settings-table">
                                <tr>
                                    <th>Username</th>
                                    <td>{{ username }}</td>
                                </tr>
                                <tr>
                                    <th>Password</th>
                                    <td><p class="mb-0">{{ password }}</p></td>
                                </tr>
                            </table>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="2" class="my-6">
                        <v-card-text>
                            <v-row>
                                <v-col cols="1">
                                    <v-btn icon small color="indigo darken-2" :to="{name : 'user-openvpn-config' }">
                                        <font-awesome-icon :icon="['fas', 'angle-left']" style="font-size: 16px;" fixed-width/>
                                    </v-btn>
                                </v-col>
                                <v-col cols="11">
                                    <p class="mb-0 mt-1" style="text-align: start;">
                                        <router-link :to="{ name: 'user-openvpn-config' }">OpenVPN Settings</router-link>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
table.settings-table {
    width: 100%;
}
table.settings-table th {
    background-color: #d0d0d0;
    padding: 4px;
}
table.settings-table td {
    background-color: #f0f0f0;
    padding: 4px;
}
table.settings-table td p {
    word-wrap: break-word;
}
</style>

<script>
import { mapState /* , mapGetters */ } from 'vuex';

export default {
    data: () => ({
        username: null,
        password: null,
    }),
    computed: {
        ...mapState({
            account: (state) => state.account,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        showTable() {
            return this.username || this.password;
        },
    },
    methods: {
        async createOpenvpnPassword() {
            try {
                const response = await this.$client.user(this.user.id).user.createOpenvpnPassword();
                console.log(`Got response from createOpenvpnPassword: ${JSON.stringify(response)}`);
                const { username, password } = response;
                this.username = username;
                this.password = password;
            } catch (err) {
                console.log(`Failed to create openvpn password: ${err.message}`);
            }
        },
    },
};
</script>
